import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

import Intl from 'components/Intl';
import RoundButton from 'components/typography/RoundButton';
import Input from './Input';
import Select from './Select';
import Checkbox from './Checkbox';

import vw from 'src/styles/utils';
import Insight from 'src/plugins/insight';

const Form = () => {
  const siteKey = '6LfloNIZAAAAAJ-xeTdCTK0IocTKKHq4acwpN0kz';
  const $form = useRef();
  const [submitting, setSubmitting] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  useEffect(() => {
    const initRecaptcha = async () => {
      try {
        await loadReCaptcha(siteKey);
        setRecaptchaLoaded(true);
        console.log('reCAPTCHA loaded successfully');
      } catch (error) {
        console.error('Error loading reCAPTCHA:', error);
      }
    };

    initRecaptcha();
    document.getElementById('are_you_simulated').style.display = 'none';
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (submitting) {
      return false;
    }

    if (!recaptchaLoaded || !window.grecaptcha) {
      alert('Please wait while we initialize the form security.');
      return false;
    }

    var form = $form.current;
    var missing = '';
    var required = { contact_email: 'Email' };
    var customRequired = document.querySelectorAll(
      'input:required, textarea:required, select:required'
    );
    var questionsRequired = {
      //agent: 'Are you an agent',
    };

    // Adds custom required inputs to the 'required' object
    for (var i = 0; i < customRequired.length; i++) {
      required[customRequired[i].id] = customRequired[
        i
      ].parentNode.firstElementChild.innerHTML.replace('*', '');
    }

    // Adds required question inputs to the 'required' object
    for (var key in questionsRequired) {
      console.log(questionsRequired);
      required[key] = questionsRequired[key];
    }

    // Iterates through required fields and adds any that have
    // not been populated to 'missing' list
    for (var key in required) {
      var elements = Array.from(
        document.querySelectorAll("[id^='" + key + "']")
      );

      if (elements.length > 0) {
        var missing_field = true;

        elements.forEach(function (el) {
          if (
            el.length < 1 ||
            (el &&
              ((el.type === 'checkbox' && el.checked) ||
                (el.type === 'radio' && el.checked) ||
                (el.type !== 'radio' && el.type !== 'checkbox' && el.value) ||
                (document.getElementById(key + '_other_text') &&
                  document.getElementById(key + '_other_text').value)))
          ) {
            missing_field = false;
          }
        });

        if (missing_field) {
          missing += ' ';
        }
      }
    }

    // Tests email input value against RFC 5322 Official Standard Email Regex
    var email = document.getElementById('contact_email').value;
    if (
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      )
    ) {
      missing += ' ';
    }

    if (missing !== '') {
      alert('Please fill out the required fields.');
      return false;
    }

    setSubmitting(true);

    try {
      // Execute reCAPTCHA with Promise wrapper
      const executeRecaptcha = () => {
        return new Promise((resolve, reject) => {
          window.grecaptcha.ready(async () => {
            try {
              const token = await window.grecaptcha.execute(siteKey, {
                action: 'registration',
              });
              resolve(token);
            } catch (error) {
              reject(error);
            }
          });
        });
      };

      const token = await executeRecaptcha();
      console.log('reCAPTCHA token generated:', token ? 'success' : 'failed');

      // Add token to form
      let tokenInput = document.querySelector('.g-recaptcha-response');
      if (!tokenInput) {
        tokenInput = document.createElement('input');
        tokenInput.type = 'hidden';
        tokenInput.className = 'g-recaptcha-response';
        tokenInput.name = 'g-recaptcha-response';
      }
      tokenInput.value = token;
      form.appendChild(tokenInput);

      // Gather form data
      const name = `${document.getElementById('contact[first_name]').value} ${
        document.getElementById('contact_last_name').value
      }`;
      const email = document.getElementById('contact_email').value;
      const postal = document.getElementById('contact_postcode').value;
      const pageName = 'New Registrant - ' + postal;

      // Track insight
      await Insight({ name, email, pageName });

      // Submit form
      form.submit();
    } catch (error) {
      console.error('Form submission failed:', error);
      setSubmitting(false);
      alert(
        'There was an error verifying your submission. Please try again in a few moments.'
      );
    }
  };

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root
          action="https://hook.us1.make.com/9uq73lh5ug1z2r3d1s7q91vklsf6ky0j"
          method="post"
          accept-charset="UTF-8"
          ref={$form}
        >
          {recaptchaLoaded && (
            <ReCaptcha sitekey={siteKey} action="registration" />
          )}
          <input name="utf8" type="hidden" value="✓" />
          <input
            type="hidden"
            name="authenticity_token"
            value="tVpuBwsDDWyeKnDW5Ur8Rvtdx1Fs2aoJzLbWetG7Y4OSdPHva4F76yUH9pp9N0VJ4b+oOgyTeLAvFuUJsBQMQA=="
          />
          <input
            id="redirect_success"
            name="redirect_success"
            type="hidden"
            value={window.location.origin + getLocaleURL() + '/thank-you'}
          />
          <input
            type="hidden"
            name="redirect_error"
            id="redirect_error"
            value={window.location.origin + getLocaleURL() + '/register'}
          />
          <input
            type="text"
            name="are_you_simulated"
            id="are_you_simulated"
            placeholder="Leave this field blank"
          />
          <input type="hidden" name="source" id="source" />
          <Input
            name="contact[first_name]"
            id="contact[first_name]"
            type="text"
            label={t.registerPage.firstName}
            required
          />
          <Input
            name="contact[last_name]"
            id="contact_last_name"
            type="text"
            label={t.registerPage.lastName}
            required
          />
          <Input
            name="contact[email]"
            id="contact_email"
            type="email"
            label={t.registerPage.email}
            required
          />
          <Input
            name="contact[phone]"
            id="contact_phone"
            type="tel"
            label={t.registerPage.phone}
            required
          />
          <Input
            name="contact[postcode]"
            id="contact_postcode"
            type="text"
            label={t.registerPage.postal}
            required
          />
          <Select
            name="answers[7369][answers]"
            id="answers_7369"
            label={t.registerPage.howHeard}
            options={t.registerPage.howHeardOptions}
          />
          <Select
            name="answers[7363][answers]"
            id="answers_7363"
            label={t.registerPage.typeHome}
            options={t.registerPage.typeHomeOptions}
          />
          <Select
            name="agent"
            id="agent"
            required
            label={t.registerPage.realtor}
            options={t.registerPage.realtorOptions}
          />
          <Checkbox />
          <RoundButton
            handleClick={(e) => handleFormSubmit(e)}
            text={t.registerPage.submit}
          />
        </Root>
      )}
    </Intl>
  );
};

export default Form;

const Root = styled.form`
  ${vw('padding-top', 60, 80)}
  ${vw('padding-bottom', 60, 80)}
  #g-recaptcha {
    display: none;
  }
`;
